import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../../../../contexts/general-context";
import { useModals } from "../../../../contexts/modals-context";

import { dictionary } from "../../../../dictionary";

import { fetchInputBynumberAndSign } from "../../../../services/inputs";
import NavButton from "../../../../components/NavButton";

import ConfigIcon from "../../../../images/config.svg";
import cameraIcon from "../../../../images/camera.svg";
import mapIcon from "../../../../images/map.png";
import XRedIcon from "../../../../images/xred.svg";




const InputManage = () => {
    const [input, setInput] = useState({ name: '', mac: '', registered: false });
    const { language } = useGeneral();
    const { setLoader } = useModals();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { id, inputNumber } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const data = await fetchInputBynumberAndSign(inputNumber, id);
            setInput(data);
            document.title = `Administrar ${data.name}`;
            setLoader(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Administrar {input.name}</h2>
                </div>
                <div className='config-content'>
                    <NavButton imgSrc={ConfigIcon} text="General"
                        onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/general`)}
                    />
                    <NavButton imgSrc={cameraIcon} text="Camaras"
                        onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/cameras`)}
                    />
                    <NavButton imgSrc={mapIcon} text="Plano"
                        onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/map`)}
                    />
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/inputs`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default InputManage;
