import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import NavButton from "../../components/NavButton";
import { useGeneral } from "../../contexts/general-context";
import { useModals } from "../../contexts/modals-context";
import { dictionary, tabNames } from "../../dictionary";

import TestIcon from "../../images/test.svg";
import XRedIcon from "../../images/xred.svg";
import { fetchSings } from "../../services/sings";
import { testCommand } from "../../services/commands";

const Test = () => {
    const [sings, setSigns] = useState([])
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const navigate = useNavigate();
    const location = useLocation();

    const test = async (mac) => {
        try {
            setLoader(true);
            await testCommand(mac);
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.test.success.header[language],
                content: dictionary.modals.alert.test.success.content[language],
                func: () => { }
            });
        } catch (error) {
            console.log('Error: ', error)
            setLoader(false);
            setAlert({
                show: true,
                title: dictionary.modals.alert.test.error.header[language],
                content: dictionary.modals.alert.test.error.content[language],
                func: () => { }
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchSings()
            setSigns(data)
        }
        fetchData();
        document.title = tabNames.test[language];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>{tabNames.test[language]}</h2>
                </div>
                <div className="column">
                    {sings.map((sing, index) =>
                        <NavButton
                            key={index}
                            imgSrc={TestIcon}
                            text={sing.name}
                            onClick={() => test(sing.mac)}
                        />
                    )}
                </div>
            </main>
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(location.state === '/settings' ? '/settings' : '/')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default Test;
