import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";
import { fetchEvac, fetchSing, updateEvac } from "../../../services/sings";

import { dictionary } from "../../../dictionary";

import SaveIcon from "../../../images/save.svg";
import XRedIcon from "../../../images/xred.svg";


const SignEvacuationForm = () => {
    const [sign, setSign] = useState();
    const [evac, setEvac] = useState({ LEDWhite: false, LEDRed: false });

    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const { id } = useParams()

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: evac,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true);
                updateEvac(id, values)
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => {
                        navigate(`/settings/signs/${id}/manage`);
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                const singData = await fetchSing(id)
                document.title = `Cartel ${singData.name} Evacuacion`;
                setSign(singData);
                setEvac(await fetchEvac(id));
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.general.GETError.header[language],
                    content: dictionary.modals.alert.general.GETError.content[language],
                    func: () => { }
                });
            }

        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Cartel {sign?.name} Evacuacion</h2>
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='input-group'>
                            <span>
                                <input
                                    className="input-input"
                                    type="checkbox"
                                    checked={formik.values.LEDWhite}
                                    onChange={(e) => formik.setFieldValue('LEDWhite', e.target.checked)}
                                />
                                LED blanco
                            </span>
                        </div>
                        <div className='input-group'>
                            <span>
                                <input
                                    className="input-input"
                                    type="checkbox"
                                    checked={formik.values.LEDRed}
                                    onChange={(e) => formik.setFieldValue('LEDRed', e.target.checked)}
                                />
                                LED Rojo
                            </span>
                        </div>
                        <button
                            className='button'
                            type="submit"
                        >
                            <img src={SaveIcon} alt="" className='icon' />
                            {dictionary.common.save[language]}
                        </button>
                    </form>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/manage`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default SignEvacuationForm;
