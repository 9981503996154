import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGeneral } from "../../contexts/general-context";
import { useModals } from "../../contexts/modals-context";
import { fetchUsers } from "../../services/users";
import { dictionary, tabNames } from "../../dictionary";

import AddIcon from "../../images/add.svg";
import XRedIcon from "../../images/xred.svg";
import EditIcon from "../../images/edit.png";


function Users() {
    const [users, setUsers] = useState([])
    const navigate = useNavigate();
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                setUsers(await fetchUsers());
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.users.GETError.header[language],
                    content: dictionary.modals.alert.users.GETError.content[language],
                    func: () => { }
                });
            }
        }
        document.title = tabNames.accessCodes[language];
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>{tabNames.accessCodes[language]}</h2>
                </div>
                <div>
                    <button
                        className='navbutton'
                        onClick={() => navigate(`/settings/users/create`)}
                    >
                        <img src={AddIcon} alt={dictionary.config.accessCodes.addUser[language]} />
                        <span>{dictionary.config.accessCodes.addUser[language]}</span>
                    </button>
                    <div style={{ padding: '8px' }}>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        ID
                                    </th>
                                    <th scope="col">
                                        {dictionary.config.accessCodes.username.label[language]}
                                    </th>
                                    <th scope="col">
                                        {dictionary.config.accessCodes.accessLevel.label[language]}
                                    </th>
                                    <th scope="col">
                                    {dictionary.common.edit[language]}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) =>
                                    <tr key={user.id}>
                                        <th scope="row">{user.id}</th>
                                        <td>{user.username}</td>
                                        <td>{user.levelAccess}</td>
                                        <td 
                                        style={{cursor: 'pointer'}}
                                        onClick={() => navigate(`/settings/users/${user.id}`)}
                                        >
                                            <center>
                                                <img
                                                    src={EditIcon}
                                                    alt=""
                                                    height={20}
                                                    idth='auto'
                                                />
                                            </center>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate('/settings')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default Users;
