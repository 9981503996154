import React, { useEffect, useState } from "react";

import ItemMenu from "../ItemMenu";
import { useModals } from "../../contexts/modals-context";
import { addNearbySing, fetchNearbySignsAvailable } from "../../services/sings";
import XRedIcon from "../../images/xred.svg";
import SignIcon from "../../images/sign.svg";

const SignSelector = () => {
    const [signs, setSigns] = useState([]);
    const { signSelector, setSignSelector, setLoader, setAlert } = useModals();

    const selectSign = async (signId) => {
        try {
            setSignSelector({ ...signSelector, show: false, id: 0 })
            setLoader(true);
            await addNearbySing(signSelector.id, signId)
            setSignSelector({ ...signSelector, show: false, id: 0, selected: true })
            setLoader(false);
        } catch (error) {
            console.log(error);
            setAlert({
                show: true,
                title: "Error",
                content: "Error seleccionando cartel",
                func: () => { }
            });
        }
        setLoader(false);
    }

    useEffect(() => {
        if (signSelector.id) {
            fetchNearbySignsAvailable(signSelector.id)
                .then(signs => setSigns(signs))
        }
    }, [signSelector.id])

    return (
        <div className="modal" style={{ display: signSelector.show ? 'block' : 'none' }}>
            <div className="modal-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                    <h3 className="modal-header">Selecciona cartel</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {signs.map(sign => <ItemMenu
                        key={sign.id}
                        image={SignIcon}
                        text={sign.name}
                        func={() => {
                            selectSign(sign.id)
                        }}
                    />)}
                    <ItemMenu
                        image={XRedIcon}
                        text='Cerrar'
                        func={() => setSignSelector({ ...signSelector, show: false, id: 0, selected: false })}
                    />
                </div>
            </div>
        </div>
    );
}

export default SignSelector;