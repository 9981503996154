import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";
import { fetchInputs } from "../../../services/inputs";
import { fetchSing } from "../../../services/sings";
import NavButton from "../../../components/NavButton";

import { dictionary } from "../../../dictionary";
import XRedIcon from "../../../images/xred.svg";
import InputsIcon from "../../../images/layers.svg";

const SignListInputs = () => {
    const [inputs, setInputs] = useState([]);
    const [sign, setSign] = useState([]);
    const { language } = useGeneral();
    const { setLoader } = useModals();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const signData = await fetchSing(id);
            setSign(signData);
            setInputs(await fetchInputs(id));
            document.title = `Entradas ${signData.name}`;
            setLoader(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Lista entradas {sign.name}</h2>
                </div>
                <div className='config-content'>
                    {inputs.map((input) => <NavButton imgSrc={InputsIcon} text={input.name} onClick={() => navigate(`/settings/signs/${id}/inputs/${input.number}/manage`)} />)}
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/manage`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default SignListInputs;
