
export const statusLyE = {
    en: [
        "Online",
        "Offline",
        "Alarm",
        "Technical alarm",
        "Fault",
        "Disabled",
        "Emergency button",
        "Medical Button",
        "Evacuation button",
        "Fire button",
        "Disabled",
        "Evacuation",
        "Simulacrum",
        "Test",
        "Flour evacuated",
        "Security",
        "Traffic direction ascending",
        "Traffic direction descending",
        "Pre-alarm"
    ], es: [
        "En linea",
        "Fuera de linea",
        "Alarma",
        "Alarma Tecnica",
        "Falla",
        "Excluida",
        "Pulsador Emergencia",
        "Pulsador Medico",
        "Pulsador Evacuacion",
        "Pulsador Incendio",
        "Deshabilitado",
        "Evacuación",
        "Simulacro",
        "Test",
        "Nivel evacuado",
        "Seguridad",
        "Sentido de trafico subiendo",
        "Sentido de trafico bajado",
        "Pre alarma"
    ],
    pt: [
        "On-line",
        "Desligado",
        "Alarme",
        "Alarme Técnico",
        "Falha",
        "Excluído",
        "Botão de emergência",
        "Botão Médico",
        "Botão de evacuação",
        "Botão de incêndio",
        "Desabilitado",
        "Simulacro",
        "Teste",
        "Nível evacuado",
        "Segurança",
        "Sensação do tráfego para cima",
        "Direção do tráfego para baixo",
        "pré-alarme"
    ],
    it: [
        "On-line",
        "Disconnesso",
        "Allarme",
        "Allarme tecnico",
        "Macanza",
        "Escluso",
        "Pulsante di emergenza",
        "Pulsante di medico",
        "Pulsante di evacuazione",
        "Pulsante di fuoco",
        "Disabilitato",
        "evacuazione",
        "Simulacro",
        "Test",
        "Livello evacuato",
        "Sicurezza",
        "Direzione del traffico in alto",
        "Direzione del traffico in discesa",
        "Preallarme"
    ]
}

export const dictionary = {
    common: {
        close: { es: "Cerrar", en: "Close", pt: "Fechar", it: "Uscita" },
        save: { es: "Guardar", en: "Save", pt: "Salvar", it: "Salva" },
        delete: { es: "Eliminar", en: "Delete", pt: "Eliminar", it: "Eliminare" },
        selectFile: { es: "Seleccionar archivo", en: "Select file", pt: "Selecionar arquivo", it: "Seleziona il file" },
        reset: { es: "Reiniciar", en: "Reset", pt: "Reiniciar", it: "Ripristina" },
        relay: { es: "Rele", en: "Relat", pt: "Relé", it: "Relé" },
        edit: { es: "Editar", en: "Edit", pt: "Editar", it: "Modificare" }
    },
    modals: {
        loader: {
            header: { es: "Cargando", en: "Loading", pt: "Carregando", it: "Caricamento" }
        },
        alert: {
            default: {
                success: {
                    header: { es: "Exito en guardar", en: "Success in save", pt: "Sucesso em salvar", it: "Successo nel salva" },
                    content: { es: "Datos guardados correctamente", en: "Data saved successfully", pt: "Dados salvos com sucesso", it: "Dati salvati con successo" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: "Error enviando datos", en: "Error sending data", pt: "Erro enviando dados", it: "Errore durante l'invio dei dati" }
                }
            },
            accessCodes: {
                incomplete: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Campos incompletos', en: "Incomplete fields", pt: "Campos incompletos", it: "Campi incompleti" }
                },
                delete: {
                    header: { es: "Exito", en: "Success", pt: "Sucesso", it: "Successo" },
                    content: { es: 'Usuario eliminado', en: "User deleted", pt: "Usuário excluído", it: "Utente eliminato" }
                }
            },
            restore: {
                backupDownload: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error descargando backup', en: "Error downloading backup", pt: "Erro ao baixar backup", it: "Errore durante il download del backup" }
                },
                invalidFileExt: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Extensión de archivo invalida', en: "Invalid file extension", pt: "Extensão de arquivo inválida", it: "Estensione file non valida" }
                },
                fileNotSelected: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Archivo no seleccionado', en: "File not selected", pt: "Arquivo não selecionado", it: "File non selezionato" }
                }
            },
            factoryReset: {
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error haciendo reset de fabrica', en: "Error doing factory reset", pt: "Erro ao fazer a redefinição de fábrica", it: "Errore durante il ripristino delle impostazioni di fabbrica" }
                }
            },
            evacuation: {
                success: {
                    header: { es: "Enviado", en: "Sent", pt: "Enviado", it: "Inviato" },
                    content: { es: "Evacuacion enviado correctamente", en: "Evacuation sent correctly", pt: "Evacuação enviado corretamente", it: "Evacuazione inviato correttamente" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error enviando evacuacion', en: "Error sending evacuation", pt: "Erro ao enviar evacuação", it: "Errore nell'invio del evacuazione" }
                }
            },
            simulacrum: {
                success: {
                    header: { es: "Enviado", en: "Sent", pt: "Enviado", it: "Inviato" },
                    content: { es: "Simulacro enviado correctamente", en: "Simulacrum sent correctly", pt: "Simulacro enviado corretamente", it: "Simulacro inviato correttamente" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error enviando simulacro', en: "Error sending simulacrum", pt: "Erro ao enviar simulacro", it: "Errore nell'invio del simulacro" }
                }
            },
            test: {
                success: {
                    header: { es: "Enviado", en: "Sent", pt: "Enviado", it: "Inviato" },
                    content: { es: "Test enviado correctamente", en: "Test sent correctly", pt: "Teste enviado corretamente", it: "Test inviato correttamente" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error enviando test', en: "Error sending test", pt: "Erro ao enviar teste", it: "Errore nell'invio del test" }
                }
            },
            acknowledge: {
                success: {
                    header: { es: "Enviado", en: "Sent", pt: "Enviado", it: "Inviato" },
                    content: { es: "Aceptación enviado correctamente", en: "Acknowledge sent successfully", pt: "Aceitação enviada com sucesso", it: "Accettazione inviata correttamente" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error enviando aceptación', en: "Error sending acknowledge", pt: "Erro ao enviar aceitação", it: "Errore nell'invio dell'accettazione" }
                }
            },
            reset: {
                success: {
                    header: { es: "Enviado", en: "Sent", pt: "Enviado", it: "Inviato" },
                    content: { es: "Reset enviado correctamente", en: "Reset sent successfully", pt: "Redefinição enviada com sucesso", it: "Reimpostazione inviata con successo" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error enviando comando', en: "Error sending command", pt: "Erro ao enviar comando", it: "Errore nell'invio del comando" }
                }
            },
            acknowledgeInput: {
                header: { es: "Aceptacion", en: "Acknowledge", pt: "Aceitação", it: "Accettazione" },
                content: { es: "Entrada de aceptacion recibida", en: "Acknowledge input recived", pt: "Entrada de aceitação recebida", it: "Ingresso di accettazione ricevuto" }
            },
            resetInput: {
                header: { es: "Reset", en: "Reset", pt: "Redefinição", it: "Reimpostazione" },
                content: { es: "Reset enviado correctamente", en: "Reset input recived", pt: "Entrada de redefinição recebida", it: "Ingresso di reimpostazione ricevuto" }
            },
            commands: {
                success: {
                    header: { es: "Enviado", en: "Sent", pt: "Enviado", it: "Inviato" },
                    content: { es: "Comando enviado correctamente", en: "Command sent successfully", pt: "Comando enviado com sucesso", it: "Comando inviato con successo" }
                },
                error: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error enviando reset', en: "Error sending reset", pt: "Erro ao enviar redefinição", it: "Errore durante l'invio del ripristino" }
                }
            },
            general: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo informacion general', en: "Error getting detection lines", pt: "Erro ao obter linhas de detecção", it: "Errore nel reperire informazioni generali" }
                }
            },
            lines: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo lineas de deteccion', en: "Error getting detection lines", pt: "Erro ao obter linhas de detecção", it: "Errore nell'acquisizione delle linea di rilevazione" }
                }
            },
            inputs: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo entradas', en: "Error getting inputs", pt: "Erro ao obter entradas", it: "Errore nell'acquisizione delle IN" }
                }
            },
            users: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo codigos de acceso', en: "Error getting access codes", pt: "Erro ao obter código de acess", it: "Errore nell'acquisizione delle codice d'accesso" }
                }
            },
            network: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo configuracion de red', en: "Error getting network settings", pt: "Erro a configuração da", it: "Errore nell'acquisizione della configurazione di rete" }
                }
            },
            outputsTimers: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo informacion de Timers de salidas', en: "Error getting information from outputs timers", pt: "Erro ao obter informações do Temporizadores de saída", it: "Errore nell'ottenimento delle informazioni di cronometri di partenza" }
                }
            },
            telegram: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo informacion de APP Telegram', en: "Error getting information from Telegram APP", pt: "Erro ao obter informações do APP Telegram", it: "Errore nell'ottenimento delle informazioni dall'APP di Telegram" }
                }
            },
            reports: {
                GETError: {
                    header: { es: "Error", en: "Error", pt: "Erro", it: "Errore" },
                    content: { es: 'Error obteniendo reporte de eventos', en: "Error getting events report", pt: "Erro ao obter relatório de Evento", it: "Errore nell'acquisizione delle rapporto sull'evento" }
                }
            },
        },
        language: {
            header: { es: "Alerta", en: "Alert", pt: "Alerta", it: "Mettere in guardia" },
            content: { es: "Idioma cambiado", en: "Language changed", pt: "Lenguagem alterada", it: "Il linguaggio é cambiato" },
        },
        restore: {
            header: { es: "Restaurando...", en: "Restoring...", pt: "Restaurando...", it: "Ripristino..." },
            content: {
                general: { es: "General", en: "General", pt: "Geral", it: "Generale" },
                lines: { es: "Líneas de detección", en: "Detection lines", pt: "Linhas de detecção", it: "Linea di rilevazione" },
                inputs: { es: "Entradas", en: "Inputs", pt: "Entradas", it: "IN" },
                accessCodes: { es: "Usuarios", en: "Users", pt: "Usuários", it: "Utenti" },
                outputsTimers: { es: "Timers de salidas", en: "Outputs timers", pt: "Temporizadores de saída", it: "Cronometri di partenza" },
                network: { es: "Aprovisionamiento / enlace a red WiFi", en: "TCP-IP network provisioning / binding", pt: "Provisionamento / ligação de rede WiFi", it: "Provisioning / associazione della rete WiFi" },
                telegram: { es: "Telegram", en: "Telegram", pt: "Telegram", it: "Telegram" }
            },
        },
        commands: {
            direct: { es: "Directo", en: "Direct", pt: "Direto", it: "Dritto" },
            cancel: { es: "Cancelar", en: "Cancel", pt: "Cancelar", it: "Annullare" }
        }
    },
    statusBar: {
        alarm: { es: "Alarma General", en: "General alarm", pt: "Alarme geral", it: "Allarme generale" },
        fault: { es: "Falla General", en: "General fault", pt: "Falha geral", it: "Macanza Generale" },
        disconnect: { es: "Desconexion", en: "Disconnection", pt: "Desconexão", it: "Exclude" },
        playing: {
            evac: { es: "Reproduciondo mensaje de evacuacion", en: "Playing evacuation message", pt: "Reproduzindo mensagem de evacuação", it: "Riproduzione del messaggio di evacuazione" },
            simu: { es: "Reproduciondo mensaje de simulacro", en: "Playing simulation message", pt: "Reproduzindo mensagem de simulação", it: "Riproduzione del messaggio di esercitazione " },
            test: { es: "Reproduciondo mensaje de test", en: "Playing test message", pt: "Reproduzindo mensagem de teste", it: "Riproduzione del messaggio di prova" },
            off: { es: "Sin mensajes en reproducción", en: "No messages playing", pt: "Nenhuma mensagem sendo reproduzida", it: "Nessun messaggio in riproduzione" }
        },
        network: {
            normal: { es: "WiFi", en: "WiFi", pt: "WiFi", it: "WiFi" },
            fault: { es: "No WiFi", en: "Not WiFi", pt: "não WiFi", it: "Non WiFi" }
        },
        lock: {
            level1: { es: "Nivel acceso, 1", en: "Access level, 1", pt: "Nível de acesso, 1", it: "Livello di accessi, 1" },
            level2: { es: "Nivel acceso, 2", en: "Access level, 1", pt: "Nível de acesso, 2", it: "Livello di accessi, 2" },
            lock: { es: "Nivel acceso, bloqueado", en: "Access level, lock", pt: "Nível de acesso, bloqueado", it: "Livello di accessi, bloccato" }
        }
    },
    main: {
        header: { es: "Alertas", en: "Alerts", pt: "Alertas", it: "Avvisi" },
        buttons: {
            acknowledge: { es: "Aceptacion", en: "Acknowledge", pt: "Reconhecimento", it: "Aceptazione" },
            reset: { es: "Reset", en: "Reset", pt: "Reiniciar", it: "Ripristina" },
            test: { es: "Test", en: "Test", pt: "Teste", it: "Test" },
            command: { es: "Comandos", en: "Commands", pt: "Comandos", it: "Comandi" },
            settings: { es: "Configuración", en: "Settings", pt: "Configuração", it: "Configurazione" }
        },
        bar: {
            sign: {
                name: { es: "Cartel ", en: "Sign ", pt: "Cartaz ", it: "Cartellone " },
                status: {
                    es: statusLyE.es,
                    en: statusLyE.en,
                    pt: statusLyE.pt,
                    it: statusLyE.pt
                }
            },
            test: { es: "<strong>Test</strong> Activado", en: "<strong>Test</strong> Activated", pt: "<strong>Teste</strong> Ativado", it: "<strong>Test</strong> Attivato" }
        }
    },
    config: {
        menu: {
            header: { es: "Configuración", en: "Settings", pt: "Configuração", it: "Configurazione" },
            general: { es: "General", en: "General", pt: "Geral", it: "Generale" },
            commands: { es: "Comandos", en: "Commands", pt: "Comandos", it: "Comandi" },
            accessCodes: { es: "Usuarios", en: "Users", pt: "Usuários", it: "Utenti" },
            test: { es: "Test", en: "Test", pt: "Teste", it: "Test" },
            telegram: { es: "Configuración de APP Telegram", en: "Telegram APP Configuration", pt: "Configuração do APP Telegram", it: "Configurazione dell'APP Telegram" },
            reports: { es: "Reporte de eventos", en: "Event report", pt: "Relatório de Evento", it: "Rapporto sull'evento" },
            backupAndRestore: { es: "Copia de seguridad y restauracion", en: "Backup and restore", pt: "Backup e restauração", it: "Backup e ripristino" },
            factoryReset: { es: "Restablecer a valores de  Fabrica", en: "Restore factory values", pt: "Redefinir para as configurações de fábrica", it: "Ripristina le impostazioni di fabbrica" }
        },
        general: {
            installationName: {
                label: { es: "Nombre de Instalacion", en: "Installation name", pt: "Nome da instalação", it: "Nome dell'impianto" },
                input: { es: "Nombre de Instalacion", en: "Installation name", pt: "Nome da instalação", it: "Nome dell'impianto" }
            },
            manualsURL: {
                label: { es: "Manuales de Sistema en URL", en: "System Manuals at URL", pt: "Manuais do sistema em URL", it: "Manuali di sistema all'URL" },
                input: { es: "Manuales de Sistema en URL", en: "System Manuals at URL", pt: "Manuais do sistema em URL", it: "Manuali di sistema all'URL" }
            },
            equipmentSheetURL: {
                label: { es: "Planilla Extintores, Mangueras de Incendio y EFAISA", en: "Fire Extinguishers, Fire Hoses Worksheet", pt: "Planilha Extintores de Incêndio, Mangueiras de Incêndio", it: "Estintori, manichette antincendio e foglio di lavoro" },
                input: { es: "Planilla Extintores, Mangueras de Incendio y EFAISA", en: "Fire Extinguishers, Fire Hoses Worksheet", pt: "Planilha Extintores de Incêndio, Mangueiras de Incêndio", it: "Estintori, manichette antincendio e foglio di lavoro" }
            },
            location: {
                label: { es: "Ubicacion de Equipo", en: "Equipment Location", pt: "Localização do equipamento", it: "Posizione dell'attrezzatura" },
                input: { es: "Ubicacion de Equipo", en: "Equipment Location", pt: "Localização do equipamento", it: "Posizione dell'attrezzatura" }
            },
            datetime: {
                label: { es: "Fecha y Hora", en: "Date and Time", pt: "Data e hora", it: "Data e ora" },
            },
            systemType: {
                label: { es: "Tipo de sistema", en: "System type", pt: "Tipo de sistema", it: "Tipo di sistema" },
                select: {
                    es: ["Bizona", "Bizona+", "CDI4", "CDI54", "Modulo de ampliacion deteccion CDI54-8", "Modulo de extincion CDI54 EX", "SCRUM"],
                    en: ["Bizone", "Bizone+", "CDI4", "CDI54", "CDI54-8 detection expansion module", "CDI54 EX extinguishing Module", "SCRUM"],
                    pt: ["Bizona", "Bizona+", "CDI4", "CDI54", "Módulo de expansão de detecção CDI54-8", "Módulo de extinção CDI54 EX", "SCRUM"],
                    it: ["Bizona", "Bizona+", "CDI4", "CDI54", "Modulo di espansione rilevamento CDI54-8", "Modulo di spegnimento CDI54 EX", "SCRUM"]
                }
            },
            LinesAndInputsAvailable: {
                header: { es: "Líneas y entradas disponibles", en: "Lines and inputs available", pt: "Linhas e entradas disponíveis", it: "Linee e IN disponibili" },
                lines: { es: "líneas", en: "lines", pt: "linhas", it: "linee" },
                inputs: { es: "entradas", en: "inputs", pt: "entradas", it: "IN" },
            },
            notes: {
                label: { es: "Notas", en: "Notes", pt: "Notas", it: "Dettaglio" },
                input: { es: "Notas", en: "Notes", pt: "Notas", it: "Dettaglio" }
            }
        },
        lines: {
            subHeader: { es: "Línea de detección Nº ", en: "Detection line Nº ", pt: "Linha de detecção Nº ", it: "Linea di rilevamento Nº " },
            enable: { es: "Habilitada", en: "Enabled", pt: "Ativada", it: "Attivato" },
            alias: {
                label: { es: "Nombre de línea", en: "Line name", pt: "Nome da linha", it: "Nome della linea" },
                input: { es: "Nombre de línea", en: "Line name", pt: "Nome da linha", it: "Nome della linea" },
            },
            camera: {
                direct: { es: "Reproducción en pestaña", en: "Tab playback", pt: "reprodução de aba", it: "riproduzione diretta" },
                url: {
                    label: { es: "URL de cámara", en: "Camera CCTV URL", pt: "URL da Câmera CFTV", it: "URL della Telecamera TVCC" },
                    input: { es: "URL de cámara", en: "Camera CCTV URL", pt: "URL da Câmera CFTV", it: "URL della Telecamera TVCC" },
                }
            }
        },
        inputs: {
            subHeader: { es: "Entrada Nº ", en: "Input Nº ", pt: "Entrada Nº ", it: "IN Nº " },
            enable: { es: "Habilitada", en: "Enabled", pt: "Ativada", it: "Attivato" },
            alias: {
                label: { es: "Nombre de entrada", en: "Input name", pt: "Nome de entrada", it: "Nome il IN" },
                input: { es: "Nombre de entrada", en: "Input name", pt: "Nome de entrada", it: "Nome il IN" }
            },
            type: {
                label: { es: "Tipo de entrada", en: "Input type", pt: "Tipo de entrada", it: "Tipo di IN" },
                select:
                {
                    es: statusLyE.es.map((value, index) => [2, 3, 4, 6, 7, 8, 9, 10, 14, 15, 16, 17].includes(index) ? value : null),
                    en: statusLyE.en.map((value, index) => [2, 3, 4, 6, 7, 8, 9, 10, 14, 15, 16, 17].includes(index) ? value : null),
                    pt: statusLyE.pt.map((value, index) => [2, 3, 4, 6, 7, 8, 9, 10, 14, 15, 16, 17].includes(index) ? value : null),
                    it: statusLyE.it.map((value, index) => [2, 3, 4, 6, 7, 8, 9, 10, 14, 15, 16, 17].includes(index) ? value : null)
                }

            },
            camera: {
                direct: { es: "Reproducción en pestaña", en: "Tab playback", pt: "reprodução de aba", it: "riproduzione diretta" },
                url: {
                    label: { es: "URL de cámara", en: "Camera Camera CCTV URL", pt: "URL da Câmera CFTV", it: "URL della Telecamera TVCC" },
                    input: { es: "URL de cámara", en: "Camera Camera CCTV URL", pt: "URL da Câmera CFTV", it: "URL della Telecamera TVCC" },
                }
            }
        },
        commands: {
            commands:
            {
                es: [
                    "Relé alarma Riesgo 1",
                    "Relé alarma Riesgo 2",
                    "Relé alarma Riesgo 3",
                    //"Alarma línea 1",
                    //"Alarma línea 2"
                ],
                en: [
                    "Risk alarm relay 1",
                    "Risk alarm relay 2",
                    "Risk alarm relay 3",
                    //"Alarm line 1",
                    //"Alarm line 2"
                ],
                pt: [
                    "Relé de alarme de risco 1",
                    "Relé de alarme de risco 1",
                    "Relé de alarme de risco 1",
                    //"Alarme da linha 1",
                    //"Alarme da linha 2"
                ],
                it: [
                    "Relè allarme rischio 1",
                    "Relè allarme rischio 2",
                    "Relè allarme rischio 3",
                    //"Allarme linea 1",
                    //"Allarme linea 2"
                ]
            }
        },
        outputsTimers: {
            ton: { es: "Temporizado de encendido", en: "ON timer", pt: "Tempo de ignição", it: "Tempo di accensione" },
            toff: { es: "Temporizado de apagado", en: "OFF timer", pt: "Desligamento temporizado", it: "Tempo per disattivare" }
        },
        accessCodes: {
            addUser: { es: "Agregar usuario", en: "Add user", pt: "Adicionar usuário", it: "Aggiungi utente" },
            newUser: { es: "Nuevo usuario", en: "New user", pt: "Novo usuário", it: "Nuovo utente" },
            user: { es: " Usuario Nº ", en: "User Nº ", pt: "Usuário Nº ", it: "Utente Nº " },
            username: {
                label: { es: "Nombre de usuario", en: "Username", pt: "Nome de usuário", it: "Nome utente" },
                input: { es: "Nombre de usuario", en: "Username", pt: "Nome de usuário", it: "Nome utente" }
            },
            accessLevel: {
                label: { es: "Nivel de acceso", en: "Access level", pt: "Nível de acesso", it: "Livello di accesso" }
            },
            accessCode: {
                label: { es: "Codigo de acceso", en: "Access code", pt: "Código de acesso", it: "Codice d'accesso" },
                input: { es: "Codigo de acceso", en: "Access code", pt: "Código de acesso", it: "Codice d'accesso" }
            }
        },
        network: {
            sta: { es: "Red WiFi", en: "WiFi network", pt: "Rede Wi-Fi", it: "Rete Wi-Fi" },
            password: { es: "Contraseña", en: "Password", pt: "Senha", it: "Parola d'ordine" },
            ap: { es: "AP WiFi", en: "WiFi AP", pt: "Ponto de acesso Wi-Fi", it: "AP WiFi" },
            enable: { es: "Habilitada", en: "Enabled", pt: "Ativada", it: "Attivato" },
            security: { es: "Seguridad", en: "Security", pt: "Segurança", it: "Sicurezza" }
        },
        telegram: {
            token: {
                label: { es: "Token", en: "Token", pt: "Token", it: "Token" },
                input: { es: "Token", en: "Token", pt: "Token", it: "Token" }
            },
            chatID: {
                label: { es: "Chat ID", en: "Chat ID", pt: "Chat ID", it: "Chat ID" },
                input: { es: "Chat ID", en: "Chat ID", pt: "Chat ID", it: "Chat ID" }
            }
        },
        reports: {
            table: {
                header: {
                    datetime: { es: "Fecha y Hora", en: "Date and Time", pt: "Data e hora", it: "Data e ora" },
                    type: { es: "Tipo", en: "Type", pt: "Tipo", it: "Tipo" },
                    number: { es: "Número", en: "Number", pt: "Número", it: "Numero" },
                    status: { es: "Estado", en: "Status", pt: "Estado", it: "Stato" }
                },
                content: {
                    type: {
                        system: { es: "Sistema", en: "System", pt: "Sistema", it: "Sistema" },
                        line: { es: "Linea", en: "Line", pt: "Linha", it: "Linea" },
                        input: { es: "Entrada", en: "IN", pt: "IN", it: "IN" }
                    },
                    status: {
                        local: { es: "Local", en: "Local", pt: "Local", it: "Locale" },
                        remote: { es: "Remoto", en: "Remote", pt: "Remoto", it: "Remoto" }
                    }
                }
            },
            download: { es: "Descargar", en: "Download", pt: "Descarga", it: "Scaricare" }
        },
        backupAndRestore: {
            cloudBackup: {
                subHeader: { es: "Copia de seguridad cloud", en: "Cloud Backup", pt: "Backup na nuvem", it: "Backup sul cloud" },
                input: { es: "Habilitada", en: "Enabled", pt: "Ativada", it: "Attivato" }
            },
            localBackup: {
                subHeader: { es: "Copia de seguridad Local", en: "Local backup", pt: "Backup local", it: "Backup locale" },
                label: { es: "Descargar backup", en: "Download backup", pt: "Nome do backup", it: "Scarica il backup" },
                button: { es: "Descargar", en: "Download", pt: "Descarga", it: "Scaricare" }
            },
            localBackupName: {
                label: { es: "Nombre de backup", en: "Backup name", pt: "Nome do backup", it: "nome del backup" },
                input: { es: "Nombre de backup", en: "Backup name", pt: "Nome do backup", it: "nome del backup" }
            },
            restoreBackup: {
                subHeader: { es: "Restauracion", en: "Restoration", pt: "Restauração", it: "Ripristina alla versione selezionata" },
                label: { es: "Archivo de restauracion XML", en: "XML restore file", pt: "Arquivo de restauração XML", it: "File di ripristino XML" },
                button: { es: "Restaurar", en: "Restore", pt: "Restaurar", it: "Ripristina" }
            }
        },
        factoryReset: {
            header: { es: "¿Estas seguro?", en: "Are you sure?", pt: "Tem certeza?", it: "Sei sicuro?" },
            content: { es: "Se volveran a los valores de fabrica", en: "It will return to factory settings", pt: "Eles retornarão às configurações de fábrica", it: "Torneranno alle impostazioni di fabbrica" },
            yes: { es: "Si", en: "Yes", pt: "Sim", it: "Sì" },
            no: { es: "No", en: "No", pt: "Não", it: "No" }
        }
    }
}

export const tabNames = {
    start: { es: "Inicio", en: "Start", pt: "Início", it: "Inizio" },
    config: dictionary.config.menu.header,
    general: dictionary.config.menu.general,
    lines: dictionary.config.menu.lines,
    inputs: dictionary.config.menu.inputs,
    commands: dictionary.config.menu.commands,
    test: dictionary.config.menu.test,
    outputsTimers: dictionary.config.menu.outputsTimers,
    accessCodes: dictionary.config.menu.accessCodes,
    network: dictionary.config.menu.network,
    telegram: dictionary.config.menu.telegram,
    reports: dictionary.config.menu.reports,
    backupAndRestore: dictionary.config.menu.backupAndRestore,
}