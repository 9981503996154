import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { fetchCamerasInput, fetchCamerasSign } from "../services/cameras";

import { dictionary } from "../dictionary";
import { useGeneral } from "../contexts/general-context";

import bellIcon from "../images/bell.svg";
import cameraIcon from "../images/camera.svg";
import camera1Icon from "../images/camera-1.svg";
import camera2Icon from "../images/camera-2.svg";
import camera3Icon from "../images/camera-3.svg";
import camera4Icon from "../images/camera-4.svg";
import checkIcon from "../images/check.svg";
import disconnectIcon from "../images/disconnect.svg";
import evacuationIcon from "../images/evacuation.svg";
import fireDetectorsIcon from "../images/fire-detectors.png";
import doctorIcon from "../images/doctor.svg";
import faultIcon from "../images/fault.svg";
import sirenRedEvac from "../images/siren-red-evac.svg";
import sirenRedSimu from "../images/siren-red-simu.svg";
import sirenRedTest from "../images/siren-red-test.svg";
import paperIcon from "../images/paper.png";
import mapIcon from "../images/map.png";



const LineBar = ({ bar, type }) => {
    const { language } = useGeneral();
    const [color, setColor] = useState('green');
    const [status, setStatus] = useState(dictionary.main.bar.sign.status[language][0]);
    const [icon, setIcon] = useState(checkIcon);
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        const makeBar = async () => {
            if (type === 'sign') {
                if (bar.enable) {
                    if (bar.message) {
                        if (bar.message === 'emergency') {
                            setColor('red');
                            setIcon(sirenRedEvac);
                            setStatus(dictionary.main.bar.sign.status[language][11]);
                        } else if (bar.message === 'simulacrum') {
                            setColor('red');
                            setIcon(sirenRedSimu);
                            setStatus(dictionary.main.bar.sign.status[language][12]);
                        } else if (bar.message === 'test') {
                            setColor('red');
                            setIcon(sirenRedTest);
                            setStatus(dictionary.main.bar.sign.status[language][13]);
                        } else if (bar.message === 'pre-alarm') {
                            setColor('orange');
                            setIcon(bellIcon);
                            setStatus(dictionary.main.bar.sign.status[language][18]);
                        }
                    } else {
                        const inputs = bar.inputs;
                        if (Object.values(inputs).some(input => input.status !== "OFF")) {
                            const statusRank = {
                                "14": 0,
                                "15": 0,
                                "16": 0,
                                "17": 0,
                                "9": 3,
                                "8": 3,
                                "7": 3,
                                "6": 3,
                                "4": 1,
                                "3": 2,
                                "2": 3,
                            };

                            let highestStatus = null;
                            let highestRank = -1;

                            for (let key in inputs) {
                                let input = inputs[key];
                                if (input.status !== 'disconnect') {
                                    let rank = statusRank[input.status];
                                    if (rank > highestRank) {
                                        highestRank = rank;
                                        highestStatus = input.status;
                                    }
                                }
                            }
                            if (highestStatus === 2) { // Alarm
                                setColor('red');
                                setIcon(bellIcon);
                                setStatus(dictionary.main.bar.sign.status[language][2]);
                            } else if (highestStatus === 3) { // Technical alarm
                                setColor('orange');
                                setIcon(bellIcon);
                                setStatus(dictionary.main.bar.sign.status[language][3]);
                            } else if (highestStatus === 4) { // Fault
                                setColor('yellow');
                                setIcon(faultIcon);
                                setStatus(dictionary.main.bar.sign.status[language][4]);
                            } else if (highestStatus === 6) { // Emergency button
                                setColor('red');
                                setIcon(bellIcon);
                                setStatus(dictionary.main.bar.sign.status[language][6]);
                            } else if (highestStatus === 7) { // Medical Button
                                setColor('red');
                                setIcon(doctorIcon);
                                setStatus(dictionary.main.bar.sign.status[language][7]);
                            } else if (highestStatus === 8) { // Evacuation button
                                setColor('green');
                                setIcon(evacuationIcon);
                                setStatus(dictionary.main.bar.sign.status[language][8]);
                            } else if (highestStatus === 9) { // Fire button
                                setColor('red');
                                setIcon(fireDetectorsIcon);
                                setStatus(dictionary.main.bar.sign.status[language][9]);
                            } else if (bar.status === 14) { // Flour evacuated
                                setColor('blue');
                                setIcon(bellIcon);
                                setStatus(dictionary.config.inputs.type.select[language][14]);
                            } else if (bar.status === 15) { // Security
                                setColor('blue');
                                setIcon(bellIcon);
                                setStatus(dictionary.config.inputs.type.select[language][15]);
                            } else if (bar.status === 16) { // Traffic direction ascending
                                setColor('blue');
                                setIcon(bellIcon);
                                setStatus(dictionary.config.inputs.type.select[language][16]);
                            } else if (bar.status === 17) { // Traffic direction descending
                                setColor('blue');
                                setIcon(bellIcon);
                                setStatus(dictionary.config.inputs.type.select[language][17]);
                            }
                        } else {
                            if (bar.status === 'online') { //Online
                                setColor('green');
                                setIcon(checkIcon);
                                setStatus(dictionary.main.bar.sign.status[language][0]);
                            } else if (bar.status === 'offline') { // Offline
                                setColor('yellow');
                                setIcon(faultIcon);
                                setStatus(dictionary.main.bar.sign.status[language][1]);
                            }
                        }
                    }
                } else {
                    setColor('yellow');
                    setIcon(disconnectIcon);
                    setStatus(dictionary.main.bar.sign.status[language][10]);
                }
                setCameras(await fetchCamerasSign(bar.id))
            } else if (type === 'input') {
                if (bar.status === 2) { // Alarm
                    setColor('red');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][2]);
                } else if (bar.status === 3) { // Technical alarm
                    setColor('orange');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][3]);
                } else if (bar.status === 4) { // Fault
                    setColor('yellow');
                    setIcon(faultIcon);
                    setStatus(dictionary.config.inputs.type.select[language][4]);
                } else if (bar.status === 6) { // Emergency button
                    setColor('red');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][6]);
                } else if (bar.status === 7) { // Medical Button
                    setColor('blue');
                    setIcon(doctorIcon);
                    setStatus(dictionary.config.inputs.type.select[language][7]);
                } else if (bar.status === 8) { // Evacuation button
                    setColor('green');
                    setIcon(evacuationIcon);
                    setStatus(dictionary.config.inputs.type.select[language][8]);
                } else if (bar.status === 9) { // Fire button
                    setColor('red');
                    setIcon(fireDetectorsIcon);
                    setStatus(dictionary.config.inputs.type.select[language][9]);
                } else if (bar.status === 14) { // Flour evacuated
                    setColor('blue');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][14]);
                } else if (bar.status === 15) { // Security
                    setColor('blue');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][15]);
                } else if (bar.status === 16) { // Traffic direction ascending
                    setColor('blue');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][16]);
                } else if (bar.status === 17) { // Traffic direction descending
                    setColor('blue');
                    setIcon(bellIcon);
                    setStatus(dictionary.config.inputs.type.select[language][17]);
                } else { // Alarm
                    setColor('red');
                    setIcon(bellIcon);
                    setStatus(dictionary.main.bar.sign.status[language][2]);
                }
                setCameras(await fetchCamerasInput(bar.id))
            } else if (type === 'general') {
                setColor('red');
                if (bar.status === 'emergency') {
                    setIcon(sirenRedEvac);
                    setStatus(dictionary.main.bar.sign.status[language][11] + ' General');
                } else if (bar.status === 'simulacrum') {
                    setIcon(sirenRedSimu);
                    setStatus(dictionary.main.bar.sign.status[language][12] + ' General');
                } else if (bar.status === 'test') {
                    setIcon(sirenRedTest);
                    setStatus(dictionary.main.bar.sign.status[language][13] + ' General');
                }
            }
        }
        makeBar();
    }, [bar, type, language])
    return (
        <div className={`bar ${color} show`}>
            <div className='bar-content-container'>
                <img className='icon' src={icon} alt='' />
                <div className='bar-text'>
                    <strong>{bar.name}</strong>
                    <div>
                        <span>{status}</span>
                    </div>
                </div>
            </div>
            <div className="column" style={{ alignItems: 'end' }}>
                <div className='bar-content-container'>
                    {type === 'sign' &&
                        <Link to={`/sign/${bar.id}/map`} >
                            <img className='icon' src={mapIcon} alt='' />
                        </Link>
                    }
                    {type === 'input' &&
                        <Link to={`/input/${bar.id}/map`} >
                            <img className='icon' src={mapIcon} alt='' />
                        </Link>
                    }
                    {type === 'sign' &&
                        <Link to={`/sign/${bar.id}/evac-role`} >
                            <img className='icon' src={paperIcon} alt='' />
                        </Link>
                    }
                    {cameras.map((camera) => {
                        let icon = cameraIcon;
                        if (camera.number === 1) {
                            icon = camera1Icon;
                        } else if (camera.number === 2) {
                            icon = camera2Icon;
                        } else if (camera.number === 3) {
                            icon = camera3Icon;
                        } else if (camera.number === 4) {
                            icon = camera4Icon;
                        }
                        return (camera.enable &&
                            <Link key={camera.id} to={camera.url} target="_blank">
                                <img className='icon' src={icon} alt={camera.url} />
                            </Link>
                        );
                    }
                    )}
                </div>
                {bar.lastChange !== null &&
                    <span>
                        {moment(bar.lastChange).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                }
            </div>
        </div>
    );
}

export default LineBar;