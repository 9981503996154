import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";

import { dictionary, tabNames } from "../../../dictionary";

import SaveIcon from "../../../images/save.svg";
import XRedIcon from "../../../images/xred.svg";
import { editSings, fetchSing } from "../../../services/sings";





const SignForm = () => {
    const [editMode, setEditMode] = useState(false);
    const [sign, setSign] = useState({ enable: false, name: '', mac: '', registered: false });
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();

    const formik = useFormik({
        initialValues: sign,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true);
                await editSings(values.id, values);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => {
                        navigate('/settings/signs');
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    })

    useEffect(() => {
        setLoader(true);
        if (state) {
            setSign({ ...state.sing, enable: false, name: '', registered: true, register: true });
        } else {
            setEditMode(true);
        }
        setLoader(false);
        document.title = tabNames.general[language];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            setSign(await fetchSing(id))
            setLoader(false);
        }
        if (editMode) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode]);

    return (
        <>

            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>{editMode ? 'Editar cartel' : 'Registrar cartel'}</h2>
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='input-group'>
                            <span>
                                <input
                                    className="input-input"
                                    type="checkbox"
                                    checked={formik.values.enable}
                                    onChange={(e) => formik.setFieldValue('enable', e.target.checked)}
                                />
                                Habilitado
                                {/* {dictionary.config.inputs.enable[language]} */}
                            </span>
                        </div>
                        <div className='input-group'>
                            <label htmlFor="name">
                                Nombre de cartel
                            </label>
                            <input
                                id="name"
                                className="input"
                                placeholder='Nombre de cartel'
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="mac">
                                MAC
                            </label>
                            <input
                                id="mac"
                                className="input"
                                placeholder='MAC'
                                name="mac"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.mac}
                            />
                        </div>
                        <button
                            className='button'
                            type="submit"
                        >
                            <img src={SaveIcon} alt="" className='icon' />
                            {dictionary.common.save[language]}
                        </button>
                    </form>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${sign.id}/manage`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default SignForm;
