import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";
import { deleteNearbySing, fetchNearbySings, fetchSing } from "../../../services/sings";

import { dictionary } from "../../../dictionary";
import XRedIcon from "../../../images/xred.svg";
import AddIcon from "../../../images/add.svg";

const NearbySigns = () => {
    const [nearbySings, setNearbySings] = useState([]);
    const [sign, setSign] = useState(null);
    const { language } = useGeneral();
    const { signSelector, setAlert, setLoader, setSignSelector } = useModals();
    const navigate = useNavigate();
    const { id } = useParams();

    const deleteSing = async (id) => {
        try {
            setLoader(true);
            await deleteNearbySing(id);
            setNearbySings(await fetchNearbySings(id));
            setAlert({
                show: true,
                title: "Exito",
                content: "Cartel eliminado",
                func: () => { }
            });
            setLoader(false);
        } catch (error) {
            console.error(error);
            setLoader(false);
            setAlert({
                show: true,
                title: "Error",
                content: "Error eliminando cartel",
                func: () => { }
            });
        }

    }

    useEffect(() => {
        if (sign) {
            if (signSelector.selected) {
                console.log(signSelector, sign.id);
                fetchNearbySings(sign.id)
                    .then((signs) => setNearbySings(signs));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signSelector.selected]);

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const signData = await fetchSing(id);

            setSign(signData);
            setNearbySings(await fetchNearbySings(id));
            document.title = `Camaras ${signData.name}`;
            setLoader(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Carteles aledaños {sign?.name}</h2>

                </div>
                <div>
                    <button
                        className='navbutton'
                        onClick={() => setSignSelector({ show: true, id: sign?.id })}
                    >
                        <img
                            src={AddIcon}
                            alt='Agregar cartel' />
                        <span>Agregar cartel</span>
                    </button>
                    <div style={{ padding: '8px' }}>
                        <table class="table" style={{ width: 'auto' }}>
                            <thead>
                                <tr>
                                    <th scope="col">
                                        Nombre Cartel
                                    </th>
                                    <th scope="col">
                                        MAC
                                    </th>
                                    <th scope="col">
                                        Eliminar
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {nearbySings.map((sign) =>
                                    <tr key={sign.relatedSign.id}>
                                        <td>{sign.relatedSign.name}</td>
                                        <td>{sign.relatedSign.mac}</td>
                                        <td
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => deleteSing(sign.id)}
                                        >
                                            <center>
                                                <img
                                                    src={XRedIcon}
                                                    alt=""
                                                    height={20}
                                                    idth='auto'
                                                />
                                            </center>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/manage`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default NearbySigns;
