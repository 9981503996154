import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";

import { dictionary, tabNames } from "../../../dictionary";

import XRedIcon from "../../../images/xred.svg";
import { fetchEvacRole } from "../../../services/sings";


const EvacRole = () => {
    const [evacInfo, setEvacInfo] = useState({ responsible: '', procedure: '' });
    const { language } = useGeneral();
    const { setLoader } = useModals();
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            setEvacInfo(await fetchEvacRole(id));
        }
        setLoader(true);
        fetchData();
        document.title = tabNames.general[language];
        setLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Roles de evacuacion</h2>
                </div>
                <div>
                    <h4 style={{ fontSize: '32px' }}><u>Responsable:</u> {evacInfo.responsible}</h4>
                    <div class="descgral">
                        <p style={{ whiteSpace: 'preserve-breaks' }}>{evacInfo.procedure}</p>
                    </div>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default EvacRole;
