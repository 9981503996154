import axios from "axios"
import { apiDomain } from "../config"

export const fetchInputs = async (sign) => {
    const response = await axios.get(`${apiDomain}/api/inputs?sign=${sign}`)
    return (response.data);
}

export const fetchInput = async (id) => {
    const response = await axios.get(`${apiDomain}/api/inputs/${id}`)
    return (response.data);
}

export const fetchInputBynumberAndSign = async (number, sign) => {
    const response = await axios.get(`${apiDomain}/api/inputs/${number}?sign=${sign}`)
    return (response.data);
}

export const editInput = async (id, inputBody) => {
    const response = await axios.put(`${apiDomain}/api/inputs/${id}`, inputBody)
    return (response.data);
}

export const fetchMapBynumberAndSign = async (number, sign) => {
    let url = `${apiDomain}/api/inputs/${number}/map`;
    if(sign) {
        url = `${url}?sign=${sign}`
    }
    const response = await axios.get(url)
    return (response.data);
}

export const updateMapBynumberAndSign = async (number, sign, mapBody) => {
    const response = await axios.put(`${apiDomain}/api/inputs/${number}/map?sign=${sign}`, mapBody)
    return (response.data);
}
