import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";
import { fetchSings } from "../../../services/sings";
import { dictionary } from "../../../dictionary";

import XRedIcon from "../../../images/xred.svg";
import Button from "../../../components/Button";


function Signs() {
    const [signs, setSigns] = useState([])
    const [unregisteredSings, setUnregisteredSings] = useState([])
    const navigate = useNavigate();
    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                const data = await fetchSings()
                setSigns(data.filter(e => e.registered === true));
                setUnregisteredSings(data.filter(e => e.registered === false));
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.users.GETError.header[language],
                    content: dictionary.modals.alert.users.GETError.content[language],
                    func: () => { }
                });
            }
        }
        document.title = 'Carteles';
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Carteles</h2>
                </div>
                <div>
                    <div style={{ padding: '8px' }}>
                        <h3>Carteles registrados</h3>
                        {signs.length > 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            MAC
                                        </th>
                                        <th scope="col">
                                            Nombre
                                        </th>
                                        <th scope="col">
                                            Ultima actividad
                                        </th>
                                        <th scope="col">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {signs.map((sign) =>
                                        <tr key={sign.id}>
                                            <th scope="row">{sign.mac}</th>
                                            <td>{sign.name}</td>
                                            <td>{sign.updatedAt}</td>
                                            <td>
                                                <Button text="Administrar" onClick={() => navigate(`/settings/signs/${sign.id}/manage`)}/>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            :
                            'Sin Carteles'
                        }
                        <h3>Carteles sin registrar</h3>
                        {unregisteredSings.length > 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            MAC
                                        </th>
                                        <th scope="col">
                                            Nombre
                                        </th>
                                        <th scope="col">
                                            Ultima actividad
                                        </th>
                                        <th scope="col">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {unregisteredSings.map((sign) =>
                                        <tr key={sign.id}>
                                            <th scope="row">{sign.mac}</th>
                                            <td>{sign.name}</td>
                                            <td>{sign.lastReport}</td>
                                            <td
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => navigate(`/settings/signs/register`, { state: { sing: sign } })}
                                            >
                                                <Button text="Registrar" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            :
                            'Sin Carteles'
                        }
                    </div>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate('/settings')}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default Signs;
