import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MapInteractionCSS } from "react-map-interaction";

import { useGeneral } from "../../../contexts/general-context";
import { useModals } from "../../../contexts/modals-context";
import { fetchMapBynumberAndSign } from "../../../services/inputs";

import { dictionary, tabNames } from "../../../dictionary";

import XRedIcon from "../../../images/xred.svg";


const MapInput = () => {
    const [map, setMap] = useState({ map: null });
    const { language } = useGeneral();
    const { setLoader } = useModals();
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            setMap(await fetchMapBynumberAndSign(id));
        }
        setLoader(true);
        fetchData();
        document.title = tabNames.general[language];
        setLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div style={{ textAlign: "center" }}>
                    <MapInteractionCSS
                        showControls
                        defaultValue={{
                            scale: 1,
                            translation: { x: 0, y: 0 }
                        }}
                        minScale={0.5}
                        maxScale={10}
                    >
                        <div className="preview-container-full">
                            <img
                                src={map.map}
                                alt=""
                                className="preview-image"
                            />
                        </div>
                    </MapInteractionCSS>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default MapInput;
