import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { useGeneral } from "../../../../contexts/general-context";
import { useModals } from "../../../../contexts/modals-context";
import { fetchMapBynumberAndSign, updateMapBynumberAndSign } from "../../../../services/inputs";

import { dictionary } from "../../../../dictionary";

import SaveIcon from "../../../../images/save.svg";
import XRedIcon from "../../../../images/xred.svg";


const InputMapForm = () => {
    const [map, setMap] = useState({ map: null });
    const [preview, setPreview] = useState(null);

    const { language } = useGeneral();
    const { setAlert, setLoader } = useModals();
    const { id, inputNumber } = useParams()

    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
                formik.setFieldValue('map', reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const formik = useFormik({
        initialValues: map,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setLoader(true);
                await updateMapBynumberAndSign(inputNumber, id, values);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.success.header[language],
                    content: dictionary.modals.alert.default.success.content[language],
                    func: () => {
                        navigate(`/settings/signs/${id}/inputs/${inputNumber}/manage`);
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                setLoader(false)
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.default.error.header[language],
                    content: dictionary.modals.alert.default.error.content[language],
                    func: () => { }
                });
            }
        },
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true);
                const mapSRC = await fetchMapBynumberAndSign(inputNumber, id);
                setMap(mapSRC);
                setPreview(mapSRC.map);
                setLoader(false);
            } catch (error) {
                console.error('Error:', error);
                setLoader(false);
                setAlert({
                    show: true,
                    title: dictionary.modals.alert.general.GETError.header[language],
                    content: dictionary.modals.alert.general.GETError.content[language],
                    func: () => { }
                });
            }

        }
        document.title = 'Plano';
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <main>
                <div className='main-header'>
                    <h2 className='main-header-title-dark'>Plano</h2>
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="input-container">
                            <label htmlFor="map-upload" className="custom-file-upload">
                                Seleccione el plano
                            </label>
                            <input
                                id="map-upload"
                                name="map"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                        </div>

                        {preview && (
                            <div className="preview-container">
                                <img
                                    src={preview}
                                    alt="Preview"
                                    className="preview-image"
                                />
                            </div>
                        )}
                        <button
                            className='button'
                            type="submit"
                        >
                            <img src={SaveIcon} alt="" className='icon' />
                            {dictionary.common.save[language]}
                        </button>
                    </form>
                </div>
            </main >
            <footer className="row">
                <button className='navbutton' onClick={() => navigate(`/settings/signs/${id}/inputs/${inputNumber}/manage`)}>
                    <img src={XRedIcon} alt='' />
                    <span>{dictionary.common.close[language]}</span>
                </button>
            </footer>
        </>
    );
}

export default InputMapForm;
